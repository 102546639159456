import useSWR, { SWRResponse } from "swr";
import useSWRMutation, { SWRMutationResponse } from "swr/mutation";
import { API_ENDPOINT } from "../environment";
import { useAuth0 } from "@auth0/auth0-react";

class ResponseError extends Error {
  status: number = 0;

  constructor(resp: Response) {
    super("An error occurred while fetching the data");
    this.status = resp.status;
  }
}

const handleResponseError = async (resp: Response) => {
  if (!resp.ok) {
    throw new ResponseError(resp);
  }
  return resp;
};

const defaultJsonResponseHandler = (resp: Response) =>
  resp.status !== 204 ? resp.json() : undefined;

export const useApi = <Data = any, Error = ResponseError>(
  path: string | null,
  init: RequestInit,
  responseHandler: (resp: Response) => any = defaultJsonResponseHandler
): SWRResponse<Data, Error> => {
  const { getAccessTokenSilently } = useAuth0();
  return useSWR(path ? `/api/${path}` : null, async () => {
    const accessToken = await getAccessTokenSilently({
      audience: `${API_ENDPOINT}/api/`,
    });
    init.headers = {
      ...init.headers,
      Authorization: `Bearer ${accessToken}`,
    };
    return await fetch(`${API_ENDPOINT}/api/${path}`, init)
      .then(handleResponseError)
      .then(responseHandler);
  });
};

export const useApiMutation = <Data = any, Error = ResponseError>(
  key: string,
  init: (options: { arg: any }) => { path: string; options: RequestInit },
  responseHandler: (resp: Response) => any = defaultJsonResponseHandler
): SWRMutationResponse<Data, Error> => {
  const { getAccessTokenSilently } = useAuth0();

  return useSWRMutation<any, any, any>(
    key,
    async (
      _key: any,
      // @ts-ignore
      context
    ) => {
      const accessToken = await getAccessTokenSilently({
        audience: `${API_ENDPOINT}/api/`,
      });
      const { path, options: requestInit } = init(context);
      requestInit.headers = {
        ...requestInit.headers,
        Authorization: `Bearer ${accessToken}`,
      };
      return await fetch(`${API_ENDPOINT}/api/${path}`, requestInit)
        .then(handleResponseError)
        .then(responseHandler);
    }
  );
};
