// src/routes/Root.tsx

import * as React from "react";
import { Outlet } from "react-router-dom";
import { SiteTitle } from "../components/SiteTitle";
import { SiteDrawer } from "../components/SiteDrawer";

// Material UI
import { Box, Grid, Toolbar } from "@mui/material";

export const Root = () => {
  return (
    <Box sx={{ display: "flex", height: "100vh" }} component="div">
      <SiteTitle />
      <SiteDrawer />
      <Grid
        container
        component="main"
        direction="column"
        flexWrap="nowrap"
        flexGrow={1}
      >
        <Grid item flexShrink={0}>
          <Toolbar />
        </Grid>
        <Grid
          item
          flexGrow={1}
          sx={{ p: 3 }}
          container
          flexWrap="nowrap"
          direction="column"
          alignItems="center"
        >
          <Box maxWidth="lg" width="100%" flexGrow="1">
            {/* Content */}
            <Outlet />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
