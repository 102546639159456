// src/routes/Dashboard.tsx

import React from "react";
import { R2TableEntry } from "../components/R2Table";
import { UploadHandler } from "../utils/UploadHandler";
import { R2Table } from "../components/R2Table";
import { useConfirm } from "material-ui-confirm";

// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useApi, useApiMutation } from "../hooks/useApi";
import { useSnackbar } from "notistack";
import { useSWRConfig } from "swr";
import { DragDropFileUpload } from "../components/DragDropFileUpload";

export const Dashboard = () => {
  ////////////////////////////////
  ////////// APP STATES //////////
  ////////////////////////////////
  // create state for R2 objects that the app should display initially
  const confirm = useConfirm();

  const { mutate } = useSWRConfig();
  const { data: r2TableEntries } = useApi<R2TableEntry[]>("data", {
    method: "GET",
  });
  const { trigger: triggerDelete } = useApiMutation(
    "files/delete",
    ({ arg }) => ({
      path: `files/${arg.item.key}`,
      options: {
        method: "DELETE",
      },
    })
  );
  const { trigger: triggerDownload } = useApiMutation<Blob>(
    "files/download",
    ({ arg }) => ({
      path: `files/${arg.item.key}`,
      options: {
        method: "GET",
      },
    }),
    (resp) => resp.blob()
  );

  const downloadFile = async (item: R2TableEntry) => {
    const fileContent = await triggerDownload({ item });
    const url = URL.createObjectURL(
      new Blob([fileContent!], {
        type: item.httpMetadata["contentType"] ?? "application/octet-stream",
      })
    );
    window.open(url);
    URL.revokeObjectURL(url);
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return (
    <div className="Dashboard">
      <div className="content">
        <R2Table
          objectProperties={r2TableEntries ?? []}
          onDownload={async (item) => {
            downloadFile(item);
          }}
          onDelete={async (item) => {
            confirm({
              title: "Supprimer ce fichier ?",
              description: `La suppression de « ${item.key} » du [bucketname] est permanente et ne peut pas être annulée.`,
              cancellationText: "Annuler",
              cancellationButtonProps: { autoFocus: true },
              confirmationText: "Supprimer",
              confirmationButtonProps: {
                startIcon: <DeleteRoundedIcon />,
                variant: "contained",
                color: "error",
              },
            })
              .then(async () => {
                await triggerDelete({ item });
                mutate(
                  `/api/data`,
                  (items) =>
                    items?.filter(({ key }: R2TableEntry) => key !== item.key),
                  { revalidate: false }
                );
                enqueueSnackbar(`Deletion of « ${item.key} » successful`, {
                  variant: "success",
                });
              })
              .catch(() => {});
          }}
        />
        <UploadHandler
          onUploaded={async (item) => {
            mutate(`/api/data`, r2TableEntries!.concat(item), {
              revalidate: false,
            });
          }}
        />
        <DragDropFileUpload
          onUploaded={async (item) => {
            mutate(`/api/data`, r2TableEntries!.concat(item), {
              revalidate: false,
            });
          }}
        />
      </div>
    </div>
  );
};
