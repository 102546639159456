// src/routes/DetailObject.tsx

import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, useParams } from "react-router-dom";
import { FC, ReactNode } from "react";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Grid from "@mui/material/Grid";
import {
  convertToReadable,
  formatter,
  beautifyContentType,
} from "../utils/formatter";
import { Box, Divider, IconButton, List } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useConfirm } from "material-ui-confirm";
import { contentDispFormat } from "../utils/formatter";

// Icons
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Loading } from "../components/Loading";
import { useApi, useApiMutation } from "../hooks/useApi";
import { R2TableEntry } from "../components/R2Table";
import { UserChip } from "../components/UserChip";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useSWRConfig } from "swr";

const TypoComponent: FC<{
  header: string;
  fieldInput: string | ReactNode | undefined;
}> = (props) => {
  return (
    <>
      <Typography color="text.secondary" fontWeight={"bold"}>
        {props.header}
      </Typography>
      <Typography mb={2}>{props.fieldInput}</Typography>
    </>
  );
};

const MetadataList: FC<{
  header: string;
  fieldInput: Record<string, string>;
}> = (props) => {
  return (
    <>
      <Typography color="text.secondary" fontWeight={"bold"}>
        {props.header}
      </Typography>
      <List>
        <Grid container spacing={2}>
          {Object.keys(props.fieldInput).map((key) => {
            return (
              <Grid key={key} item xs={2}>
                <ListItem disableGutters>
                  <ListItemText
                    primary={props.fieldInput[key]}
                    secondary={key}
                  ></ListItemText>
                </ListItem>
              </Grid>
            );
          })}
        </Grid>
      </List>
    </>
  );
};

export const DetailObject = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const { key } = useParams();
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();

  const { trigger: triggerDelete } = useApiMutation("upload", ({ arg }) => ({
    path: `files/${arg.item.key}`,
    options: {
      method: "DELETE",
    },
  }));

  const { data: file, error } = useApi<R2TableEntry>(`files/${key}/content`, {
    method: "GET",
  });

  if (error?.status === 404) {
    return (
      <Box sx={{ maxWidth: "79em" }}>
        {" "}
        {/* Wider just looks stupid */}
        <Button
          component={Link}
          to="/dashboard"
          variant="text"
          startIcon={<ArrowBackRoundedIcon />}
        >
          Tableau de bord
        </Button>
        {/* Title Area */}
        <Grid container spacing={2} mt={2} mb={3}>
          <Grid item xs={9}>
            <Typography variant="h5">
              Objet « {`${key}`} » introuvable
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (!file) return <Loading />;

  return (
    <Box>
      <Button
        component={Link}
        to="/dashboard"
        variant="text"
        startIcon={<ArrowBackRoundedIcon />}
      >
        Tableau de bord
      </Button>
      {/* Title Area */}
      <Grid
        container
        spacing={2}
        mt={2}
        mb={3}
        justifyContent="space-between"
        alignItems="center"
        wrap={"nowrap"}
      >
        <Grid item sx={{ overflow: "hidden" }}>
          <Typography variant="h5" noWrap>
            {contentDispFormat(file.httpMetadata["contentDisposition"])}
          </Typography>
        </Grid>

        <Grid item flexShrink={0}>
          <Button variant="outlined" startIcon={<FileDownloadOutlinedIcon />}>
            Télécharger
          </Button>

          <IconButton
            color={"error"}
            onClick={async () => {
              confirm({
                title: "Supprimer ce fichier ?",
                description: `La suppression de « ${file.key} » du [bucketname] est permanente et ne peut pas être annulée.`,
                cancellationText: "Annuler",
                cancellationButtonProps: { autoFocus: true },
                confirmationText: "Supprimer",
                confirmationButtonProps: {
                  startIcon: <DeleteRoundedIcon />,
                  variant: "contained",
                  color: "error",
                },
              })
                .then(async () => {
                  await triggerDelete({ item: file });
                  mutate(
                    `/api/data`,
                    (items) =>
                      items?.filter(
                        ({ key }: R2TableEntry) => key !== file.key
                      ),
                    { revalidate: false }
                  );
                })
                .then(() => {
                  enqueueSnackbar(`Deletion of « ${file.key} » successful`, {
                    variant: "success",
                  });
                })
                .then(() => {
                  navigate(`/dashboard`);
                });
            }}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Card sx={{ minWidth: 275 }} variant={"outlined"}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="text.secondary" fontWeight={"bold"}>
                Détails de l’objet
              </Typography>
            </Grid>

            {/* Top Section */}
            <Grid item xs={6}>
              <TypoComponent
                header={"Clé"}
                fieldInput={file.key}
              ></TypoComponent>
            </Grid>
            <Grid item xs={6}>
              <TypoComponent
                header={"Propriétaire de l'objet"}
                fieldInput={file && <UserChip userID={file.ownerId} />}
              ></TypoComponent>
            </Grid>

            {/* Left Column */}
            <Grid item xs={6}>
              <TypoComponent
                header={"Date de création"}
                fieldInput={formatter.format(new Date(file.uploaded))}
              ></TypoComponent>
              <TypoComponent
                header={"Taille"}
                fieldInput={convertToReadable(file.size)}
              ></TypoComponent>
            </Grid>

            {/* Right Column */}
            <Grid item xs={6}>
              <TypoComponent
                header={"Type"}
                fieldInput={beautifyContentType(
                  file.httpMetadata["contentType"] ?? ""
                )}
              ></TypoComponent>
            </Grid>

            {/* Middle Section */}
            <Grid container spacing={2} item>
              <Grid item xs={6}>
                <TypoComponent
                  header={"Version"}
                  fieldInput={file.version}
                ></TypoComponent>
              </Grid>
              <Grid item xs={6}>
                <TypoComponent
                  header={"etag"}
                  fieldInput={file.etag}
                ></TypoComponent>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Metadata */}
            <Grid item xs={12}>
              <MetadataList
                header={"Métadonnées http"}
                fieldInput={file.httpMetadata}
              ></MetadataList>
            </Grid>
            <Grid item xs={12}>
              <MetadataList
                header={"Métadonnées personnalisées"}
                fieldInput={file.customMetadata}
              ></MetadataList>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
