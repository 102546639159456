import React, { FC } from "react";
import { CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

export const Loading: FC = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      height={"100%"}
    >
      <CircularProgress />
      <Typography marginTop={1} color="text.secondary" fontWeight={"bold"}>
        Chargement
      </Typography>
    </Grid>
  );
};
