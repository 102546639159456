// src/components/AvatarString.tsx

import { Avatar } from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { AvatarProps } from "@mui/material/Avatar/Avatar";

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const AvatarString: FC<
  {
    name: string;
  } & AvatarProps
> = ({ name, ...otherProps }) => {
  const [givenName, ...otherNames] = name.split(" ");

  let initials = givenName[0];

  if (otherNames.length > 0) {
    // if we have more than one split result, take the last one
    initials += otherNames.at(-1)![0];
  }

  return (
    <>
      <Avatar
        {...otherProps}
        sx={{ bgcolor: stringToColor(name), ...otherProps.sx }}
      >
        {initials}
      </Avatar>
    </>
  );
};
