// src/routes/Home.tsx

import React from "react";

// import styles
import Typography from "@mui/material/Typography";

export const Home = () => {
  return (
    <div className="Home">
      <div className="content">
        <h1>Hello Home</h1>
        <Typography paragraph>
          Das ist Text. Den habe ich selber geschrieben. Ich kopiere mir keinen
          komischen text aus den Internet. Das ist Text. Den habe ich selber
          geschrieben. Ich kopiere mir keinen komischen text aus den Internet.
          Das ist Text. Den habe ich selber geschrieben. Ich kopiere mir keinen
          komischen text aus den Internet.
        </Typography>
      </div>
    </div>
  );
};
