// src/components/IfAuthenticated

import { useAuth0 } from "@auth0/auth0-react";
import React, { FC } from "react";

export const IfAuthenticated: FC<{
  isAuthenticated: JSX.Element;
  notAuthenticated?: JSX.Element;
}> = (props) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <></>;
  }

  if (isAuthenticated) {
    return props.isAuthenticated;
  }
  return props.notAuthenticated ?? <></>;
};
