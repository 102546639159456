// src/routes/Profile.tsx

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const Profile = () => {
  // « user » is defined as types « User | undefined »
  // by using « ! » you can get rid « undefined » and « null » cases in typescript
  // this is called « Non-null Assertion Operator »
  const user = useAuth0().user!;

  return (
    <div>
      <img src={user.picture} alt={user.name} />
      <h2>{user.name}</h2>
      <p>{user.email}</p>
    </div>
  );
};
