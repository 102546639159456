// index.tsx

import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import CssBaseline from "@mui/material/CssBaseline";
import { ConfirmProvider } from "material-ui-confirm";
import { Auth0Provider } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import { IfAuthenticated } from "./components/IfAuthenticated";
import { SnackbarProvider } from "notistack";

// Import functionality for Router
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// import routes
import { Root } from "./routes/Root";
import { ErrorPage } from "./error-page";
import { Dashboard } from "./routes/Dashboard";
import { Home } from "./routes/Home";
import { Recent } from "./routes/Recent";
import { Starred } from "./routes/Starred";

// Import styles
import { Profile } from "./routes/Profile";
import Typography from "@mui/material/Typography";
import { LoginButton } from "./components/LoginButton";
import { DetailObject } from "./routes/DetailObject";
import { API_ENDPOINT } from "./environment";
import { Loading } from "./components/Loading";
import { SWRConfig } from "swr";
import { DetailUser } from "./routes/DetailUser";

// interface for Protected/Unprotected Routes
interface Props {
  children?: React.ReactNode;
}

// only allow access to route, if session token is NOT NULL
const ProtectedRoute = ({ children }: Props) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <Loading />;
  }

  if (user !== undefined && isAuthenticated) {
    return <>{children}</>;
  }
  return (
    <div className="AuthError">
      <h1>YOU FAILED</h1>
      <Typography paragraph>You need to log in to continue, moron !</Typography>
      <IfAuthenticated
        isAuthenticated={<></>}
        notAuthenticated={<LoginButton />}
      ></IfAuthenticated>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "dashboard",
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: "recent",
        element: <Recent />,
      },
      {
        path: "starred",
        element: <Starred />,
      },
      {
        path: "detail/:key",
        element: (
          <ProtectedRoute>
            <DetailObject />
          </ProtectedRoute>
        ),
      },
      {
        path: "user/:key",
        element: (
          <ProtectedRoute>
            <DetailUser />
          </ProtectedRoute>
        ),
      },
      {
        path: "profile",
        element: (
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <CssBaseline />
    <SWRConfig
      value={{
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }}
    >
      <Auth0Provider
        audience={`${API_ENDPOINT}/api/`}
        domain={"dev-evzcuyarogf64z1t.eu.auth0.com"}
        clientId={"QSJm95hc8aII3SQDrgLlS7QPoObfKxnV"}
        redirectUri={window.location.origin}
      >
        <ConfirmProvider>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
          >
            <RouterProvider router={router} />
          </SnackbarProvider>
        </ConfirmProvider>
      </Auth0Provider>
    </SWRConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
