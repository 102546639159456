// src/hooks/useApiFileUpload.ts

import { useApiMutation } from "./useApi";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { R2TableEntry } from "../components/R2Table";
import { andlistFormatter } from "../utils/formatter";

export const useApiFileUpload = () => {
  const { trigger } = useApiMutation("upload", ({ arg }) => ({
    path: "upload",
    options: {
      method: "POST",
      body: arg.file,
      headers: {
        //"content-type": file.type,
        //"content-length": `${file.size}`, // 👈 Headers need to be a string
        "content-disposition": `attachment; filename = ${arg.file.name}`,
      },
    },
  }));

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const uploadFiles = useCallback(async (files: FileList) => {
    // If length of array is 0 just return
    if (!files.length) return [];

    let uploadArray: R2TableEntry[] = [];
    for (const file of files) {
      uploadArray.push(await trigger({ file }));
    }
    const keys = uploadArray.map((object) => `« ${object.key} »`);
    enqueueSnackbar(`Upload of ${andlistFormatter.format(keys)} successful`, {
      variant: "success",
    });
    return uploadArray;
  }, []);

  return {
    uploadFiles,
  };
};
