// src/hooks/useDragDrop.ts

import { useCallback, useEffect, useRef, useState } from "react";

export const useDragDrop = () => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [droppedFiles, setDroppedFiles] = useState<FileList | undefined>();

  const dragCounter = useRef(0);

  const handleDrag = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);
  const handleDragIn = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter.current++;
    if ((event.dataTransfer?.items.length ?? 0) > 0) {
      setIsDragging(true);
      setDroppedFiles(undefined);
    }
  }, []);
  const handleDragOut = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current > 0) return;
    setIsDragging(false);
  }, []);
  const handleDrop = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    if (!event.dataTransfer) return;

    if ((event.dataTransfer.files.length ?? 0) > 0) {
      dragCounter.current = 0;
      setDroppedFiles(event.dataTransfer.files);
      event.dataTransfer!.clearData();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("dragenter", handleDragIn);
    window.addEventListener("dragleave", handleDragOut);
    window.addEventListener("dragover", handleDrag);
    window.addEventListener("drop", handleDrop);
    return function cleanUp() {
      window.removeEventListener("dragenter", handleDragIn);
      window.removeEventListener("dragleave", handleDragOut);
      window.removeEventListener("dragover", handleDrag);
      window.removeEventListener("drop", handleDrop);
    };
  }, []);

  return { isDragging, droppedFiles };
};
