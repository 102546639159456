import { FC } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useAuth0 } from "@auth0/auth0-react";
import { User } from "@auth0/auth0-spa-js";
import * as React from "react";
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { AvatarString } from "./AvatarString";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

const ProfileMenuHeaderBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 2),
}));

export const ProfileMenu: FC = () => {
  const auth0 = useAuth0();

  // we know when this is used, that user is always there so just require all fields
  const user = auth0.user as Required<User>;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleMenu} color="inherit">
        <AvatarString name={user.name} />
        <KeyboardArrowDownIcon sx={{ width: 20, height: 20 }} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box sx={{ width: 320, maxWidth: "100%" }}>
          <ProfileMenuHeaderBox>
            <Stack spacing={0.5} alignItems="center">
              <div>
                <AvatarString sx={{ width: 75, height: 75 }} name={user.name} />
              </div>
              <Typography variant="body1">{user.name}</Typography>
              {user.email !== user.name && (
                <Typography variant="body2" color="text.secondary">
                  {user.email}
                </Typography>
              )}
            </Stack>
          </ProfileMenuHeaderBox>
          <Divider sx={{ marginBottom: 1 }} />
          <MenuItem onClick={handleClose} component={Link} to={"profile"}>
            <ListItemIcon>
              <AccountBoxIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Mon Profil</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Paramètres</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => auth0.logout()}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Déconnexion</ListItemText>
          </MenuItem>
        </Box>
      </Menu>
    </div>
  );
};
