// src/utils/formatter.ts

// Create formatter to format dates and time to a better readable format
export const formatter = new Intl.DateTimeFormat(undefined, {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
});

export const convertToReadable = (number: number) => {
  if (number < 1024) {
    return `${number} octets`;
  } else if (number >= 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)} Ko`;
  } else if (number >= 1048576) {
    return `${(number / 1048576).toFixed(1)} Mo`;
  }
};

export const beautifyContentType = (input: string): string => {
  let [, type] = input.split("/");
  if (type === "plain") {
    type = "text";
  }
  return type ?? "";
};

export const formatMetadata = (input: Record<string, string>): string => {
  return Object.keys(input).length > 0
    ? JSON.stringify(input, null, 2)
    : "Aucune métadonnée définie";
};

export const contentDispFormat = (input: unknown): string => {
  if (typeof input !== "string") return "blubb";

  // extract the part after file name
  const result = /filename ?= ?(.*)$/.exec(input);
  const fileName = result ? result[1] : "";

  // normalize filename and strip its " and take the part before the dot as name
  const [name] = (fileName ?? "").replace(/"/g, "").split(".");

  return name ?? "";
};

export const andlistFormatter = new Intl.ListFormat(undefined, {
  style: "long",
  type: "conjunction",
});
