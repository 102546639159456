// src/utils/UploadHandler.tsx

import { ChangeEvent, FC } from "react";
import { R2TableEntry } from "../components/R2Table";
import Button from "@mui/material/Button";
import { useApiFileUpload } from "../hooks/useApiFileUpload";

export const UploadHandler: FC<{
  onUploaded: (object: R2TableEntry[]) => any;
}> = (props) => {
  const { uploadFiles } = useApiFileUpload();

  const handleFilesChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    const uploadedEntries = await uploadFiles(e.target.files);

    props.onUploaded(uploadedEntries);
  };

  return (
    <Button variant="outlined" component="label">
      Télécharger
      <input hidden multiple type="file" onChange={handleFilesChange} />
    </Button>
  );
};
