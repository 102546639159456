// src/components/R2Table.tsx

import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import {
  beautifyContentType,
  convertToReadable,
  formatter,
} from "../utils/formatter";
import { useNavigate } from "react-router-dom";

// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import ImageIcon from "@mui/icons-material/Image";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Divider, Menu, MenuItem } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { UserChip } from "./UserChip";

export interface R2TableEntry {
  key: string;
  ownerId: string;
  version: string;
  size: number;
  etag: string;
  httpEtag: string;
  uploaded: string;
  httpMetadata: Record<string, string>;
  customMetadata: Record<string, string>;
}

const FALLBACK_FILE_ICON = <InsertDriveFileIcon />;
const icons: Record<string, JSX.Element> = {
  audio: <AudioFileIcon />,
  image: <ImageIcon />,
  video: <VideoFileIcon />,
  "application/pdf": <PictureAsPdfIcon />,
};

const mimeIcon = (mimeType: string): JSX.Element => {
  // full mime type matches
  if (icons.hasOwnProperty(mimeType)) return icons[mimeType];

  const [type] = mimeType.split("/");

  // first path of the mime matches
  if (icons.hasOwnProperty(type)) return icons[type];

  return FALLBACK_FILE_ICON;
};

interface RowAction {
  icon: JSX.Element;
  label: string;
  onClick: (row: R2TableEntry) => any;
}

export const R2Table = (props: {
  objectProperties: Array<R2TableEntry>;
  onDownload: (item: R2TableEntry) => void;
  onDelete: (item: R2TableEntry) => void;
}) => {
  // Define columns to use in DataGrid
  const columns: GridColumns<R2TableEntry> = [
    {
      field: "icons",
      headerName: "",
      width: 50,
      renderCell: (params) =>
        mimeIcon(params.row.httpMetadata["contentType"] ?? ""),
    },
    {
      field: "key",
      headerName: "Nom",
      width: 230,
    },
    {
      field: "ownerId",
      headerName: "Propriétaire",
      width: 200,
      renderCell: (params) => <UserChip userID={params.row.ownerId} />,
    },
    {
      field: "uploaded",
      headerName: "Modifié le",
      width: 180,
      valueGetter: (params) => formatter.format(new Date(params.row.uploaded)),
    },
    {
      field: "size",
      headerName: "Taille",
      width: 100,
      valueGetter: (params) => convertToReadable(params.row.size),
    },
    {
      field: "type",
      headerName: "Type",
      width: 100,
      valueGetter: (params) =>
        beautifyContentType(params.row.httpMetadata["contentType"] ?? ""),
    },
  ];

  const onContextMenu = (e: React.SyntheticEvent) => {
    let target = e.target as HTMLElement;
    console.log(target.innerHTML);
  };

  const [selectedRow, setSelectedRow] = React.useState<R2TableEntry>();
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const navigate = useNavigate();

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    const rowId = event.currentTarget.getAttribute("data-id");
    setSelectedRow(props.objectProperties.find((item) => item.key === rowId));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const rowActionGroups: RowAction[][] = [
    [
      {
        icon: <InfoOutlinedIcon />,
        label: "Afficher les détails",
        onClick: (row: R2TableEntry) => navigate(`/detail/${row.key}`),
      },
      {
        icon: <DriveFileMoveOutlinedIcon />,
        label: "Déplacer vers",
        onClick: () => console.log("Déplacé clicked"),
      },
      {
        icon: <StarBorderRoundedIcon />,
        label: "Ajouter aux favoris",
        onClick: () => console.log("Ajouté aux favoris clicked"),
      },
      {
        icon: <DriveFileRenameOutlineOutlinedIcon />,
        label: "Renommer",
        onClick: () => console.log("Renommer clicked"),
      },
      {
        icon: <FileDownloadOutlinedIcon />,
        label: "Télécharger",
        onClick: (row: R2TableEntry) => props.onDownload(row),
      },
    ],
    [
      {
        icon: <DeleteRoundedIcon />,
        label: "Supprimer",
        onClick: (row: R2TableEntry) => props.onDelete(row),
      },
    ],
  ];

  const menuList = (groups: typeof rowActionGroups) => {
    return groups
      .map((group, idx) => [
        group.map((item) => (
          <MenuItem
            key={item.label}
            onClick={() => {
              console.log(selectedRow);
              item.onClick(selectedRow!);
              handleClose();
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        )),
        // unless the last group is reached, add a divider
        idx < groups.length - 1 ? <Divider key={`divider-${idx}`} /> : null,
      ])
      .flat();
  };

  return (
    <Box
      component="div"
      sx={{ height: 650, width: "100%" }}
      //onContextMenu={onContextMenu}
    >
      <DataGrid
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
        }}
        getRowId={(row) => row.key}
        rows={props.objectProperties}
        onRowDoubleClick={(params) => {
          navigate(`/detail/${params.row.key}`);
        }}
        columns={columns}
        autoPageSize
        rowsPerPageOptions={[5]}
        experimentalFeatures={{ newEditingApi: true }}
        componentsProps={{
          row: {
            onContextMenu: handleContextMenu,
            style: { cursor: "context-menu" },
          },
        }}
      />
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
      >
        {menuList(rowActionGroups)}
      </Menu>
    </Box>
  );
};
