import { forwardRef } from "react";
import { NavLink } from "react-router-dom";
import * as React from "react";

export const MuiNavLink = forwardRef((props: any, ref) => (
  <NavLink
    ref={ref}
    {...props}
    className={({ isActive }) =>
      isActive ? props.className + " Mui-selected" : props.className
    }
    end
  />
));
