// src/components/UserChip.tsx

import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { AvatarString } from "./AvatarString";
import { useApi } from "../hooks/useApi";
import { FC } from "react";

export const UserChip: FC<{
  userID: string;
}> = (props) => {
  const { data } = useApi(`users/${props.userID}/user`, {
    method: "GET",
  });

  if (!data) return <></>;

  const userId = encodeURI(props.userID);

  return (
    <Stack direction="row" spacing={1}>
      <Chip
        avatar={<AvatarString name={data.name} />}
        label={`${data.name} ${data.familyname}`}
        component="a"
        href={`/user/${userId}`}
        clickable
      />
    </Stack>
  );
};
