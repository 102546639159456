// src/components/DragDropFileUpload.tsx

import { Backdrop, Grid } from "@mui/material";
import React, { FC, useEffect } from "react";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import { useDragDrop } from "../hooks/useDragDrop";
import { useApiFileUpload } from "../hooks/useApiFileUpload";
import { R2TableEntry } from "./R2Table";

export const DragDropFileUpload: FC<{
  onUploaded: (object: R2TableEntry[]) => any;
}> = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  const { isDragging, droppedFiles } = useDragDrop();
  const { uploadFiles } = useApiFileUpload();

  useEffect(() => {
    const uploadDroppedFiles = async () => {
      if (!droppedFiles) return;
      const result = await uploadFiles(droppedFiles);
      props.onUploaded(result);
    };

    uploadDroppedFiles();
  }, [droppedFiles]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isDragging}
      >
        <Grid
          container
          direction={"column"}
          justifyItems={"center"}
          alignItems={"center"}
          spacing={0}
        >
          <Grid item xs={12}>
            <CloudUploadRoundedIcon color="inherit" sx={{ fontSize: 200 }} />
          </Grid>
          <Grid item xs={12} sx={{ fontSize: 32 }}>
            Déposez des fichiers pour les importer.
          </Grid>
        </Grid>
      </Backdrop>
    </>
  );
};
