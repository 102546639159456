// src/routes/DetailUser.tsx

import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, useParams } from "react-router-dom";
import { FC } from "react";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Grid from "@mui/material/Grid";
import { Box, List } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

// Icons
import { Loading } from "../components/Loading";
import { useApi } from "../hooks/useApi";

const TypoComponent: FC<{
  header: string;
  fieldInput: string | undefined;
}> = (props) => {
  return (
    <>
      <Typography color="text.secondary" fontWeight={"bold"}>
        {props.header}
      </Typography>
      <Typography mb={2}>{props.fieldInput}</Typography>
    </>
  );
};

const MetadataList: FC<{
  header: string;
  fieldInput: Record<string, string>;
}> = (props) => {
  return (
    <>
      <Typography color="text.secondary" fontWeight={"bold"}>
        {props.header}
      </Typography>
      <List>
        <Grid container spacing={2}>
          {Object.keys(props.fieldInput).map((key) => {
            return (
              <Grid key={key} item xs={2}>
                <ListItem disableGutters>
                  <ListItemText
                    primary={props.fieldInput[key]}
                    secondary={key}
                  ></ListItemText>
                </ListItem>
              </Grid>
            );
          })}
        </Grid>
      </List>
    </>
  );
};

export const DetailUser = () => {
  const { key } = useParams();

  const { data } = useApi(`users/${key}/user`, {
    method: "GET",
  });

  if (!data) return <Loading />;

  return (
    <Box>
      <Button
        component={Link}
        to="/dashboard"
        variant="text"
        startIcon={<ArrowBackRoundedIcon />}
      >
        Tableau de bord
      </Button>
      {/* Title Area */}

      <Card sx={{ minWidth: 275 }} variant={"outlined"}>
        <CardContent>
          <Grid container spacing={2}>
            {/* Top Section */}
            <Grid item xs={12}>
              <TypoComponent
                header={"User id"}
                fieldInput={data.id}
              ></TypoComponent>
            </Grid>

            {/* Middle Section */}
            <Grid item xs={4}>
              <TypoComponent
                header={"Name"}
                fieldInput={data.name}
              ></TypoComponent>
            </Grid>
            <Grid item xs={4}>
              <TypoComponent
                header={"Familyname"}
                fieldInput={data.familyname}
              ></TypoComponent>
            </Grid>
            <Grid item xs={4}>
              <TypoComponent
                header={"E-Mail"}
                fieldInput={data.email}
              ></TypoComponent>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
