// src/components/SiteTitle.tsx

import * as React from "react";
import { IfAuthenticated } from "./IfAuthenticated";
import { LoginButton } from "./LoginButton";

// MUI
import { AppBar, Toolbar, Typography } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";

// Icons
import AdbIcon from "@mui/icons-material/Adb";
import SearchIcon from "@mui/icons-material/Search";
import { ProfileMenu } from "./ProfileMenu";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.01),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export const SiteTitle = () => {
  return (
    <AppBar
      position="fixed"
      elevation={2}
      sx={{
        bgcolor: "white",
        color: "black",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }} // give AppBar zIndex "theme" and make theme always zIndex+1 of drawer
    >
      <Toolbar>
        <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
        <Typography
          variant="h6"
          component="div"
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          C'est un titre
        </Typography>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
        <Box sx={{ flexGrow: 1 }} />

        <IfAuthenticated
          isAuthenticated={<></>}
          notAuthenticated={<LoginButton />}
        />

        <IfAuthenticated isAuthenticated={<ProfileMenu />} />
      </Toolbar>
    </AppBar>
  );
};
