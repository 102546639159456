// src/components/SiteDrawer.tsx

import { Box, Divider, Drawer, List, Toolbar } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import DashboardRoundedIcon from "@mui/icons-material/Dashboard";
import ListItemText from "@mui/material/ListItemText";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import * as React from "react";
import { SvgIcon } from "@mui/material";
import { MuiNavLink } from "../utils/MuiNavLink";

const drawerWidth = 180;

interface NavigationItem {
  title: string;
  url: string;
  iconComponent: typeof SvgIcon;
}

const primaryNavigation: Array<NavigationItem> = [
  { title: "Accueil", url: "/", iconComponent: HomeRoundedIcon },
  {
    title: "Tableau de bord",
    url: "dashboard",
    iconComponent: DashboardRoundedIcon,
  },
];

const secondaryNavigation: Array<NavigationItem> = [
  { title: "Récent", url: "recent", iconComponent: AccessTimeIcon },
  {
    title: "étoilé",
    url: "starred",
    iconComponent: StarBorderRoundedIcon,
  },
];

const drawerList = (listInput: Array<NavigationItem>) => {
  return (
    <List>
      {listInput.map((item) => (
        <ListItem key={item.title} disablePadding>
          <ListItemButton component={MuiNavLink} to={item.url}>
            <ListItemIcon>
              <item.iconComponent />
            </ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export const SiteDrawer = () => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar /> {/* Makes content visible in Drawer */}
      <Box sx={{ overflow: "auto" }} component="div">
        {drawerList(primaryNavigation)}
        <Divider />
        {drawerList(secondaryNavigation)}
      </Box>
    </Drawer>
  );
};
